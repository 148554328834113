import { CUSTOM_REQUEST_HEADERS } from '../../constants'
import { parseSubdomain } from '../../helpers/stringUtils'
import { REFRESH_TOKEN_EXPIRED } from '../../strings'

// Can not use `useMutation` here coz `useMutation` needs to be inside `ApolloProvider`.

export const refreshAccessToken = async (refreshToken, graphQLEndpoint) => {
  const subdomain = parseSubdomain(window.location.hostname)
  const query = JSON.stringify({
    query: `mutation refreshAccessToken {
      refreshAccessToken(refreshToken: "${refreshToken}") {
        token
        refreshToken
      }
    }`,
  })
  const response = await fetch(graphQLEndpoint, {
    headers: {
      'content-type': 'application/json',
      [CUSTOM_REQUEST_HEADERS.SUBDOMAIN_HEADER_KEY]: subdomain,
    },
    method: 'POST',
    body: query,
  })

  const responseJson = await response.json()
  const { data, errors } = responseJson
  if (errors) {
    throw new Error(REFRESH_TOKEN_EXPIRED)
  }
  return data.refreshAccessToken
}
