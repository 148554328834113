import { UserRole } from '../graphql/@types/schema'
import { FeatureGateName } from './providers/FeatureProvider/features'

export type ComponentWithNavigation<T> = React.ComponentType<T>
type ComponentWithoutNavigation = React.ComponentType
type RouteComponent<T> = ComponentWithNavigation<T> | ComponentWithoutNavigation

export interface NestedNavigationRouteConfig<T> {
  path: string
  Component: RouteComponent<T>
  requiresNavigation: boolean
}

export function requiresNavigationProps<T>(
  Component: RouteComponent<T>,
  requiresNavigation: boolean,
): Component is ComponentWithNavigation<T> {
  return requiresNavigation
}

const appRoutes = {
  root: '/',
  landing: '',
  space: '/spaces/',
  smartCodes: {
    root: '/smart-codes/',
    view: ':id',
  },
  portal: {
    root: '/portal/',
    oldSpace: ':pinCode',
    landing: 'pin-entry',
    form: {
      root: 'form/',
      location: 'location',
      space: 'space',
      spaces: 'spaces', // legacy pin code route
      assets: 'assets',
      requestSubmitSuccess: 'request/success',
      formSubmitSuccess: 'form/success',
    },
    feedback: 'feedback',
    codeScanner: 'scan',
    tickets: {
      root: 'tickets',
      viewTicket: 'tickets/:ticketId/view',
    },
  },
  staff: {
    root: '/staff/',
    dashboard: 'dashboard',
    tickets: {
      root: 'tickets/',
      viewTicket: ':ticketId/view',
      createTicket: 'new',
    },
    people: {
      root: 'people/',
      users: {
        root: 'users',
        createUser: 'new',
        editUser: ':userId/edit',
      },
      groups: {
        root: 'groups',
        createGroup: 'new',
        updateGroup: ':groupId/edit',
      },
    },
    locations: {
      root: 'locations',
      create: 'new',
      edit: {
        root: ':locationId/edit',
        spaces: {
          create: 'spaces/new',
          edit: 'spaces/:id/edit',
          spaceTypes: {
            root: 'spaces/space-types/',
            create: 'new',
            edit: ':spaceTypeId/edit',
          },
        },
      },
    },
    oldCheckEntries: 'checks',
    checkEntries: 'checks/entries',
    surveys: {
      root: 'surveys/',
      createSurvey: 'new',
      editSurvey: ':id/edit',
    },
    issues: {
      root: 'issues/',
      createIssue: 'new',
      editIssue: ':issueId/edit',
    },
    procedureTemplates: {
      root: 'procedure-templates/',
      createProcedureTemplate: 'new',
      editProcedureTemplate: ':id/edit',
      viewProcedureEntry: 'entry/:id',
    },
    assets: {
      root: 'assets/',
      createAsset: 'new',
      editAsset: ':assetId/edit',
    },
    parts: {
      root: 'parts/',
      createPart: 'new',
      editPart: ':partId/edit',
    },
    purchaseOrders: {
      root: 'purchase-orders/',
      createPurchaseOrder: 'new',
      editPurchaseOrder: ':purchaseOrderId/edit',
    },
    insight: {
      root: 'insight/',
    },
    externalOrganizations: {
      root: 'external-organizations/',
      landingPage: ':type',
      createExternalOrganization: ':type/new',
      editExternalOrganization: ':type/:id/edit',
    },
  },
  auth: {
    root: '/auth/',
    login: 'login',
    loginWithEmail: 'login/email',
    loginWithPassword: 'login/password',
    requestResetPassword: 'request-reset-password',
    changePassword: 'change-password/:token',
    ssoCallback: 'sso/callback',
    verifyToken: 'verify-token/:token',
    redirect: 'redirect',
    issueMagicLink: 'issue-magic-link',
  },
  onboarding: '/onboarding/',
  notFound: '*',
}

// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
export type RouteConfig = {
  id: string
  path: string
  // TODO: this is invoked directly in jsx as `<route.component />`
  component?: React.ReactNode | React.ElementType
  title?: string
  redirectPathname?: string
  icon?: React.ReactNode
  redirect?: {
    to: string
  }
  routes?: any[]
  // todo what is this
  decoratorText?: string
  // todo what is this
  excludeRouteMatchWithAny?: boolean
  featureGate?: FeatureGateName
  // undefined implies all roles are permitted
  permittedRoles?: UserRole[]
}

export default appRoutes
